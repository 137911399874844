import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Components
import LayoutPage from '../components/Layouts/LayoutPage';
import LoadMoreButton from '../components/Common/Button/LoadMoreButton';
import NewsItem from '../components/News/NewsItem';
import NewsList from '../components/News/NewsList';
import Section from '../components/Common/Section/Section';

const Project = ({ pageContext }) => {
  const { pageData, projectData } = pageContext;

  // Set the number of inital posts to display
  const [postsToShow, setPostsToShow] = useState(6);

  function handleLoadMore() {
    setPostsToShow(postsToShow + 6);
  }

  return (
    <LayoutPage
      backgroundImage={pageData.acf.page_hero_bg}
      title={pageData.title.rendered}
      yoastMeta={pageData.yoast_meta}
    >
      <main>
        <Section backgroundColor="#E6ECEF">
          <NewsList>
            {projectData.slice(0, postsToShow).map((item) => {
              return (
                <NewsItem
                  baseSlug="projects"
                  taxSlug="category"
                  categories={item._embedded['wp:term'][0]}
                  date={item.date}
                  image={item.acf.featured_image}
                  intro={item.acf.intro}
                  key={shortid.generate()}
                  slug={`/projects/${item.slug}`}
                  title={item.title.rendered}
                />
              );
            })}
          </NewsList>

          {postsToShow <= projectData.length && (
            <LoadMoreButton onClick={handleLoadMore}>Load more</LoadMoreButton>
          )}
        </Section>
      </main>
    </LayoutPage>
  );
};

export default Project;

Project.defaultProps = {
  pageContext: {
    pageData: {
      acf: {
        page_image_slider: null,
        content_modules: null,
      },
    },
  },
};

Project.propTypes = {
  pageContext: PropTypes.shape({
    pageData: PropTypes.shape({
      acf: PropTypes.shape({
        content_modules: PropTypes.arrayOf(PropTypes.shape({})),
        page_hero_bg: PropTypes.shape({}).isRequired,
        page_image_slider: PropTypes.shape({}),
        page_image_slider_images: PropTypes.arrayOf(PropTypes.shape({})),
      }).isRequired,
      title: PropTypes.shape({
        rendered: PropTypes.string.isRequired,
      }),
      yoast_meta: PropTypes.shape({}).isRequired,
    }).isRequired,

    projectData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
};
